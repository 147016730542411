<template>
    <div class="out_container">
        <v-row>
            <v-col cols="12" md="6">
                <v-card height="380px" elevation="1">
                    <v-card-title class="card-title text-subtitle-1">概览</v-card-title>
                    <v-divider></v-divider>
                    <div class="card_in">
                        <div class="card_l d-flex flex-column align-center justify-sm-center">
                            <el-progress type="circle" :percentage="percentage" color="#FF4949" :stroke-width="18"
                                         :format="format"></el-progress>
                            <div class="text-subtitle-1  mt-3">风险房屋</div>
                        </div>
                        <div class="card_r">
                            <div id="chart1" style="width: 100%;height:300px;"></div>
                        </div>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="card_notice" height="380px" elevation="1">
                    <v-card-title class="card-title text-subtitle-1">公告</v-card-title>
                    <v-divider></v-divider>
                    <div class="text-subtitle-1 mt-4 ml-4">{{post.content}}</div>
                    <div class="time">{{ post.date }}</div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-card height="380px" elevation="1">
                    <v-card-title class="card-title text-subtitle-1">租赁情况</v-card-title>
                    <v-divider></v-divider>
                    <!--筛选-->
                    <div class="d-flex flex-row align-center ma-3">
                        <div class="text-lg-subtitle-2 ml-1 mr-2">辖区筛选</div>
                        <el-select @change="handleChange" size="mini" v-model="value" placeholder="请选择">
                            <el-option
                                    v-for="item in allProvinces"
                                    :key="item.provinceCode"
                                    :label="item.provinceName"
                                    :value="item.provinceCode">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="d-flex justify-center align-center">
                        <div id="chart2" style="width: 100%;height:300px;"></div>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card height="380px" elevation="1">
                    <v-card-title class="card-title text-subtitle-1">区域安全</v-card-title>
                    <v-divider></v-divider>
                    <baidu-map
                            id="allMap"
                            :scroll-wheel-zoom="true"
                            @ready="mapReady">

                        <!--安全-->
                        <bm-point-collection :points="points1" color="#5470C6" size="BMAP_POINT_SIZE_BIG"
                                             @click="clickHandler"></bm-point-collection>
                        <!--警告-->
                        <bm-point-collection :points="points2" color="yellow" si`ze="BMAP_POINT_SIZE_BIG"
                                             @click="clickHandler"></bm-point-collection>
                        <!--危险-->
                        <bm-point-collection :points="points3" color="#F56C6C" size="BMAP_POINT_SIZE_BIG"
                                             @click="clickHandler"></bm-point-collection>
                        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
                        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true"
                                        :autoLocation="true"></bm-geolocation>
                    </baidu-map>
                    <div class="d-flex flex-row justify-space-around">
                        <div class="d-flex flex-row align-center mt-1">
                            <div style="font-size: 14px">安全：</div>
                            <div class="box_size" style="background-color: #5470C6"></div>
                        </div>
                        <div class="d-flex flex-row align-center mt-1">
                            <div style="font-size: 14px">警告：</div>
                            <div class="box_size" style="background-color: yellow"></div>
                        </div>
                        <div class="d-flex flex-row align-center mt-1">
                            <div style="font-size: 14px">危险：</div>
                            <div class="box_size" style="background-color: #F56C6C"></div>
                        </div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {queryRentInfo, queryRentEstateInfo, riskRoomInfo} from "network/Details/general_page/primaryHome"
    import {selectAllProvinces} from "network/Details/general_page/houseManage"
    import {getPost} from "../../network/Details/home";

    var util = require('assets/js/util.js');
    export default {
        name: "PrimaryHome",
        created() {
            //获取公告
            getPost({
                userId: this.userId
            }).then(res => {
                if (res.status === 200) {
                    this.post.content = res.data.content;
                    this.post.date = res.data.postTime;
                }
            })
            //超级管理员 风险房屋查看
            riskRoomInfo({
                userId: this.userId,
                phone: window.localStorage.getItem("phone")
            }).then(res => {
                this.riskRooms = res.data.risks;
                if (res.data.sum === 0) {
                    this.percentage = 0
                } else {
                    this.percentage = this.riskRooms / res.data.sum * 100
                }
            });
            selectAllProvinces().then(res => {
                if (res.status === 200) {
                    console.log(res.data)
                    this.allProvinces = res.data;
                    this.value = res.data[0].provinceCode;
                    this.$nextTick(() => {
                        this.showChart2();
                    })
                }
            });
            this.addPoints();
            this.date = util.formatTime(new Date());
            this.$nextTick(() => {
                this.showChart1();
            });
        },
        mounted() {
            let _this = this;
            window.onresize = function () {
                if (_this.myChart1) {
                    _this.myChart1.resize();
                }
                if (_this.myChart2) {
                    _this.myChart2.resize();
                }
            }
        },
        methods: {
            //下拉框
            handleChange() {
                this.showChart2();
            },
            clickHandler(e) {
                alert(`单击点的坐标为：${e.point.lng}, ${e.point.lat}`);
            },
            addPoints() {
                const points1 = [];
                const points2 = [];
                const points3 = [];
                for (let i = 0; i < 50; i++) {
                    const position1 = {lng: Math.random() + 106, lat: Math.random() + 29}
                    points1.push(position1);
                    if (i >= 30) {
                        const position2 = {lng: Math.random() + 106, lat: Math.random() + 29}
                        points2.push(position2);
                    }
                    if (i >= 40) {
                        const position3 = {lng: Math.random() + 106, lat: Math.random() + 29}
                        points3.push(position3);
                    }
                }
                this.points1 = points1;
                this.points2 = points2;
                this.points3 = points3;
            },
            mapReady({BMap, map}) {
                // 选择一个经纬度作为中心点
                this.center = new BMap.Point(106.54, 29.59);
                map.centerAndZoom(this.center, 12);
            },
            format() {
                return `${this.riskRooms}个`;
            },
            //图表
            showChart1() {
                let rentProportionInfo;
                queryRentInfo().then(res => {
                    if (res.status === 200) {
                        rentProportionInfo = res.data;
                        // 基于准备好的dom，初始化echarts实例
                        let myChart = this.myChart1;
                        myChart = this.$echarts.init(document.getElementById('chart1'));
                        let option = {
                            title: {
                                text: '租赁情况',
                                x: 'center',
                                y: '25px'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            legend: {
                                top: 'bottom',
                            },
                            series: [
                                {
                                    name: '详细情况',
                                    type: 'pie',
                                    radius: '50%',
                                    data: rentProportionInfo,
                                    label: {
                                        normal: {
                                            show: true,
                                            position: 'inner',
                                            textStyle: {
                                                fontWeight: 300,
                                                fontSize: 14
                                            },
                                            formatter: '{d}%'
                                        }
                                    },
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                                        }
                                    }
                                }
                            ]
                        };
                        myChart.setOption(option);
                        window.addEventListener('resize', function () {
                            if (myChart) {
                                myChart.resize();
                            }
                        });
                    }
                });
            },
            showChart2() {
                let id = this.value;
                let dataList;
                queryRentEstateInfo({
                    provinceCode: id
                }).then(res => {
                    if (res.status === 200) {
                        dataList = res.data.map(item => {
                            return {product: item.estateName, '已租': item.rentRoomNum, '未租': item.freeRoomNum}
                        });
                        // 基于准备好的dom，初始化echarts实例
                        let myChart = this.myChart2;
                        myChart = this.$echarts.init(document.getElementById('chart2'));
                        let option = {
                            legend: {
                                top: '20px',
                            },
                            tooltip: {},
                            dataset: {
                                dimensions: ['product', '已租', '未租',],
                                source: dataList
                            },
                            xAxis: {type: 'category'},
                            yAxis: {},
                            // Declare several bar series, each will be mapped
                            // to a column of dataset.source by default.
                            series: [
                                {type: 'bar'},
                                {type: 'bar'},
                            ]
                        };
                        myChart.setOption(option);
                        window.addEventListener('resize', function () {
                            if (myChart) {
                                myChart.resize();
                            }
                        });
                    }
                });
            }
        },
        data() {
            return {
                post: {
                    content: "暂无公告",
                    date: ''
                },
                //用户Id
                userId: window.localStorage.getItem("userId"),
                riskRooms: 0,
                percentage: 0,
                allProvinces: [],
                points1: [],
                points2: [],
                points3: [],
                center: "",
                myChart1: null,
                myChart2: null,
                value: '',
                options: [],
            }
        }
    }
</script>

<style lang="less" scoped>
    .box_size {
        width: 14px;
        height: 14px;
        border-radius: 14px;
    }

    /* 设定地图的大小 */
    #allMap {
        height: 300px;
        width: 100%;
    }

    .out_container {
        margin-top: 1%;
        width: 98%;
        margin-left: 1%;
    }

    .card-title {
        height: 50px;
    }

    .card_in {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-content: center;
        height: 300px;

        .card_l {
        }

        .card_r {
            width: 300px;
        }
    }

    .card_notice {
        position: relative;

        .time {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }
</style>