import {request} from "../../request";
//查询所有租赁比例
export function queryRentInfo(data){
  return request({
    url:'room/queryRentInfo',
    method:'get',
    params:data,
  })
}
//小区已租、未租情况
export function queryRentEstateInfo(data){
  return request({
    url:'room/queryRentEstateInfo',
    method:'get',
    params:data,
  })
}
//管理员辖区下的房屋数量、风险房屋数量
export function riskRoomInfo(data){
  return request({
    url:'home/riskRoom',
    method:'get',
    params:data,
  })
}